/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getZoomMeetingData = /* GraphQL */ `
  query GetZoomMeetingData($id: zoomMeetingInput) {
    getZoomMeetingData(id: $id) {
      zoomSDKKey
      zoomSDKToken
      userId
      meetingNumber
      meetingPassword
      zoomToken
      __typename
    }
  }
`;
export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers($filter: String, $limit: Int, $nextToken: String) {
    listCognitoUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phoneNumber
        bqUsername
        role
        organizationalUnitId
        organizationalUnitName
        createDate
        lastModifiedDate
        isActive
        reset_password
        customAction
        tempPassword
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getCognitoUser = /* GraphQL */ `
  query GetCognitoUser($id: String!) {
    getCognitoUser(id: $id) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
      tempPassword
      __typename
    }
  }
`;
export const listOrganizationalUnits = /* GraphQL */ `
  query ListOrganizationalUnits(
    $id: ID
    $filter: ModelOrganizationalUnitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationalUnits(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        facilityName
        organizationalUnit
        organizationalUnit_scan
        updatedAt
        deletedAt
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idNumber
        idNumber_scan
        gender
        height
        yearOfBirth
        dateOfBirth
        idOrPassport
        phoneNumber
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        deviceSize
        deviceInfo
        changesInDevice
        caregiverName
        caregiverRelation
        participationConcluded
        assessmentScores {
          timestamp
          shoulderAbduction
          fingerExtension
          WTSeconds
          WTAssistantLevel
          __typename
        }
        numberOfTreatments
        recentTreatmentDate
        controlPatient
        treatmentType
        treatmentProtocolId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        phase
        stats
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listTreatmentStages = /* GraphQL */ `
  query ListTreatmentStages(
    $filter: ModelTreatmentStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        name_scan
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        exerciseCategories
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listExercisesCategorys = /* GraphQL */ `
  query ListExercisesCategorys(
    $filter: ModelExercisesCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercisesCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_scan
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        exercises
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listExercises = /* GraphQL */ `
  query ListExercises(
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        name_scan
        name_filter
        idInApp
        tip
        description
        nameTranslations {
          code
          text
          __typename
        }
        tipTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        numOfRepetitions
        numOfSets
        secsInsteadOfReps
        illustrationFile
        progressionsList
        equipmentList
        useAssessmentScores
        shoulderAbduction
        fingerExtension
        assessmentScoresMatrix
        walkTest
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listEquipments = /* GraphQL */ `
  query ListEquipments(
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageUrl
        name
        name_scan
        idInApp
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listProgressions = /* GraphQL */ `
  query ListProgressions(
    $filter: ModelProgressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgressions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        name_scan
        nameTranslations {
          code
          text
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listTreatmentProtocols = /* GraphQL */ `
  query ListTreatmentProtocols(
    $filter: ModelTreatmentProtocolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentProtocols(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_scan
        phase
        nextPhaseMinSessions
        nextPhaseMinDays
        numberOfTreatments
        totalDaysLimit
        weeklySessionsLimit
        partialSessionsAllowed
        sessionsCounterType
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listTreatments = /* GraphQL */ `
  query ListTreatments(
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        sessionId
        timezoneOffset
        phase
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        status
        eventLog {
          timestamp
          status
          emfState
          waveformIndex
          stageId
          categoryId
          exerciseId
          __typename
        }
        notes
        treatmentId
        waveformIndex
        durations
        createdAt
        startedAt
        completedAt
        deletedAt
        zoomUserId
        zoomMeetingId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        categories {
          treatmentStageId
          exerciseCategoryId
          rating
          __typename
        }
        exercises {
          treatmentStageId
          exerciseCategoryId
          exerciseId
          rating
          status
          notes
          startedAt
          elapsed
          __typename
        }
        updateId
        updatedAt
        version
        worksheets {
          timestamp
          role
          userId
          treatmentEMFTime
          deviceEMFTime
          isTreatmentCounted
          operatorPresent
          answers {
            index
            title
            question
            meta
            answer
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serialNumber
        quickConnectNumber
        macAddress
        resistance
        deviceSize
        deletedAt
        updatedAt
        usage {
          timestamp
          tabletUserEmail
          userEmail
          patientIdNumber
          organizationalUnit
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        name_scan
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listQuestionnaires = /* GraphQL */ `
  query ListQuestionnaires(
    $filter: NameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        phase
        name
        name_scan
        description
        isActive
        items {
          index
          required
          type
          title
          question
          questionTranslations {
            code
            text
            __typename
          }
          comment
          commentTranslations {
            code
            text
            __typename
          }
          commentIndex
          options
          optionsTranslations {
            code
            text
            __typename
          }
          enableOther
          autofillField
          readOnlyAutofill
          previousQuestionIndexDependency
          mapOtherTo
          showIfParam
          showIfValue
          __typename
        }
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listQuestionnairesAnswersForPatient = /* GraphQL */ `
  query ListQuestionnairesAnswersForPatient(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnairesAnswersForPatient(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        treatmentDate
        worksheets {
          timestamp
          role
          userId
          treatmentEMFTime
          deviceEMFTime
          isTreatmentCounted
          operatorPresent
          answers {
            index
            title
            question
            meta
            answer
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listTextMessages = /* GraphQL */ `
  query ListTextMessages($filter: String, $limit: Int, $nextToken: String) {
    listTextMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        receiver
        message
        status
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listMobileDevices = /* GraphQL */ `
  query ListMobileDevices($filter: String, $limit: Int, $nextToken: String) {
    listMobileDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceUser
        bqAppVersionCode
        bqAppVersionName
        deviceId
        certificateSerialNumber
        certificateSerialNumberNumeric
        imei
        iccid
        lastSync
        model
        os
        userOU
        serialNumber
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listMobileDeviceUsers = /* GraphQL */ `
  query ListMobileDeviceUsers(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    listMobileDeviceUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        userOU
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listAuditTrails = /* GraphQL */ `
  query ListAuditTrails($filter: String, $limit: Int, $nextToken: String) {
    listAuditTrails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        dateISO
        userName
        userId
        affectedUserId
        eventName
        eventType
        description
        tableName
        dataBefore
        dataAfter
        dataDiff
        sourceOfChange
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listAuditTrailEventNames = /* GraphQL */ `
  query ListAuditTrailEventNames(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    listAuditTrailEventNames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listPatientAnalytics = /* GraphQL */ `
  query ListPatientAnalytics(
    $filter: ModelAnalyticsFilterInput
    $sort: BQSort
    $limit: Int
    $nextToken: String
  ) {
    listPatientAnalytics(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phase
        first_treatment_date
        idNumber
        participationConcluded
        incomplete_treatments_current_week
        last_treatment_date
        multiple_pauses_treatments
        number_fully_completed_treatments
        number_of_initiated_treatments
        number_of_partial_treatments
        organizationalUnit
        patientId
        treatments_current_week
        treatments_previous_week
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listTreatmentAnalytics = /* GraphQL */ `
  query ListTreatmentAnalytics(
    $filter: ModelAnalyticsFilterInput
    $sort: BQSort
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentAnalytics(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        phase
        isCounted
        app_version
        treatment_start
        device_mac
        device_emf_time
        app_emf_time
        emf_status
        first_emf_time_before_pause
        idNumber
        index
        number_of_pauses
        numOfQompasses
        organizationalUnit
        parse_status
        patientId
        qompassDetails
        session_user_email
        tablet_user_email
        status
        time_to_first_resume
        treatmentId
        week_number
        avg_category_rating
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getPatientAnalytics = /* GraphQL */ `
  query GetPatientAnalytics($id: ID!) {
    getPatientAnalytics(id: $id) {
      id
      phase
      first_treatment_date
      idNumber
      participationConcluded
      incomplete_treatments_current_week
      last_treatment_date
      multiple_pauses_treatments
      number_fully_completed_treatments
      number_of_initiated_treatments
      number_of_partial_treatments
      organizationalUnit
      patientId
      treatments_current_week
      treatments_previous_week
      __typename
    }
  }
`;
export const getSessionAnalytics = /* GraphQL */ `
  query GetSessionAnalytics($id: ID!) {
    getSessionAnalytics(id: $id) {
      session {
        id
        sessionId
        phase
        isCounted
        app_version
        treatment_start
        device_mac
        device_emf_time
        app_emf_time
        emf_status
        first_emf_time_before_pause
        idNumber
        index
        number_of_pauses
        numOfQompasses
        organizationalUnit
        parse_status
        patientId
        qompassDetails
        session_user_email
        tablet_user_email
        status
        time_to_first_resume
        treatmentId
        week_number
        avg_category_rating
        __typename
      }
      sessionEvents {
        id
        categoryId
        emfState
        exerciseId
        index
        sessionId
        stageId
        status
        timestamp
        timestamp_string
        treatmentId
        waveformIndex
        __typename
      }
      sessionCategories {
        id
        exerciseCategoryId
        index
        organizationalUnit
        patientId
        rating
        treatmentId
        treatmentStageId
        __typename
      }
      sessionExercises {
        id
        elapsed
        equipmentList
        exerciseCategoryId
        exerciseId
        index
        numOfRepetitions
        numOfSets
        organizationalUnit
        patientId
        progressionsList
        rating
        secsInsteadOfReps
        startedAt
        status
        timestamp
        treatmentId
        treatmentStageId
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listDuplicates = /* GraphQL */ `
  query ListDuplicates(
    $filter: DuplicateCheckInput
    $limit: Int
    $nextToken: String
  ) {
    listDuplicates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getOrganizationalUnit = /* GraphQL */ `
  query GetOrganizationalUnit($id: ID!) {
    getOrganizationalUnit(id: $id) {
      id
      facilityName
      organizationalUnit
      organizationalUnit_scan
      updatedAt
      deletedAt
      createdAt
      __typename
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      idNumber
      idNumber_scan
      gender
      height
      yearOfBirth
      dateOfBirth
      idOrPassport
      phoneNumber
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deviceSize
      deviceInfo
      changesInDevice
      caregiverName
      caregiverRelation
      participationConcluded
      assessmentScores {
        timestamp
        shoulderAbduction
        fingerExtension
        WTSeconds
        WTAssistantLevel
        __typename
      }
      numberOfTreatments
      recentTreatmentDate
      controlPatient
      treatmentType
      treatmentProtocolId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      phase
      stats
      deletedAt
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const getTreatmentStage = /* GraphQL */ `
  query GetTreatmentStage($id: ID!) {
    getTreatmentStage(id: $id) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      exerciseCategories
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getExercisesCategory = /* GraphQL */ `
  query GetExercisesCategory($id: ID!) {
    getExercisesCategory(id: $id) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      exercises
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getExercise = /* GraphQL */ `
  query GetExercise($id: ID!) {
    getExercise(id: $id) {
      id
      name
      name_scan
      name_filter
      idInApp
      tip
      description
      nameTranslations {
        code
        text
        __typename
      }
      tipTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      numOfRepetitions
      numOfSets
      secsInsteadOfReps
      illustrationFile
      progressionsList
      equipmentList
      useAssessmentScores
      shoulderAbduction
      fingerExtension
      assessmentScoresMatrix
      walkTest
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      imageUrl
      name
      name_scan
      idInApp
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getProgression = /* GraphQL */ `
  query GetProgression($id: ID!) {
    getProgression(id: $id) {
      id
      name
      name_scan
      nameTranslations {
        code
        text
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getTreatmentProtocol = /* GraphQL */ `
  query GetTreatmentProtocol($id: ID!) {
    getTreatmentProtocol(id: $id) {
      id
      name
      name_scan
      phase
      nextPhaseMinSessions
      nextPhaseMinDays
      numberOfTreatments
      totalDaysLimit
      weeklySessionsLimit
      partialSessionsAllowed
      sessionsCounterType
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      patientId
      sessionId
      timezoneOffset
      phase
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
        __typename
      }
      notes
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
        __typename
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
        __typename
      }
      updateId
      updatedAt
      version
      worksheets {
        timestamp
        role
        userId
        treatmentEMFTime
        deviceEMFTime
        isTreatmentCounted
        operatorPresent
        answers {
          index
          title
          question
          meta
          answer
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      serialNumber
      quickConnectNumber
      macAddress
      resistance
      deviceSize
      deletedAt
      updatedAt
      usage {
        timestamp
        tabletUserEmail
        userEmail
        patientIdNumber
        organizationalUnit
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      code
      name
      name_scan
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getDefaultDeviceForPatient = /* GraphQL */ `
  query GetDefaultDeviceForPatient($input: DefaultDeviceInput) {
    getDefaultDeviceForPatient(input: $input) {
      result
      __typename
    }
  }
`;
export const getQuestionnaire = /* GraphQL */ `
  query GetQuestionnaire($id: ID!) {
    getQuestionnaire(id: $id) {
      id
      role
      phase
      name
      name_scan
      description
      isActive
      items {
        index
        required
        type
        title
        question
        questionTranslations {
          code
          text
          __typename
        }
        comment
        commentTranslations {
          code
          text
          __typename
        }
        commentIndex
        options
        optionsTranslations {
          code
          text
          __typename
        }
        enableOther
        autofillField
        readOnlyAutofill
        previousQuestionIndexDependency
        mapOtherTo
        showIfParam
        showIfValue
        __typename
      }
      deletedAt
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const getTextMessage = /* GraphQL */ `
  query GetTextMessage($id: ID!) {
    getTextMessage(id: $id) {
      id
      sender
      receiver
      message
      status
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getMobileDeviceUser = /* GraphQL */ `
  query GetMobileDeviceUser($id: ID!) {
    getMobileDeviceUser(id: $id) {
      id
      email
      userOU
      __typename
    }
  }
`;
export const getAppSettings = /* GraphQL */ `
  query GetAppSettings($id: ID!) {
    getAppSettings(id: $id) {
      id
      data
      __typename
    }
  }
`;
export const cognitoUsersByEmail = /* GraphQL */ `
  query CognitoUsersByEmail($filter: String, $limit: Int, $nextToken: String) {
    cognitoUsersByEmail(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phoneNumber
        bqUsername
        role
        organizationalUnitId
        organizationalUnitName
        createDate
        lastModifiedDate
        isActive
        reset_password
        customAction
        tempPassword
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const organizationalUnitsByOrganizationalUnit = /* GraphQL */ `
  query OrganizationalUnitsByOrganizationalUnit(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    organizationalUnitsByOrganizationalUnit(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        facilityName
        organizationalUnit
        organizationalUnit_scan
        updatedAt
        deletedAt
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const patientsByIdNumber = /* GraphQL */ `
  query PatientsByIdNumber($filter: String, $limit: Int, $nextToken: String) {
    patientsByIdNumber(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idNumber
        idNumber_scan
        gender
        height
        yearOfBirth
        dateOfBirth
        idOrPassport
        phoneNumber
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        deviceSize
        deviceInfo
        changesInDevice
        caregiverName
        caregiverRelation
        participationConcluded
        assessmentScores {
          timestamp
          shoulderAbduction
          fingerExtension
          WTSeconds
          WTAssistantLevel
          __typename
        }
        numberOfTreatments
        recentTreatmentDate
        controlPatient
        treatmentType
        treatmentProtocolId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        phase
        stats
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const treatmentStagesByName = /* GraphQL */ `
  query TreatmentStagesByName(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    treatmentStagesByName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_scan
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        exerciseCategories
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const exercisesCategorysByName = /* GraphQL */ `
  query ExercisesCategorysByName(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    exercisesCategorysByName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_scan
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        exercises
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const exercisesByName = /* GraphQL */ `
  query ExercisesByName($filter: String, $limit: Int, $nextToken: String) {
    exercisesByName(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        name_scan
        name_filter
        idInApp
        tip
        description
        nameTranslations {
          code
          text
          __typename
        }
        tipTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        numOfRepetitions
        numOfSets
        secsInsteadOfReps
        illustrationFile
        progressionsList
        equipmentList
        useAssessmentScores
        shoulderAbduction
        fingerExtension
        assessmentScoresMatrix
        walkTest
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const equipmentsByName = /* GraphQL */ `
  query EquipmentsByName($filter: String, $limit: Int, $nextToken: String) {
    equipmentsByName(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageUrl
        name
        name_scan
        idInApp
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const progressionsByName = /* GraphQL */ `
  query ProgressionsByName($filter: String, $limit: Int, $nextToken: String) {
    progressionsByName(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        name_scan
        nameTranslations {
          code
          text
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const treatmentProtocolsByName = /* GraphQL */ `
  query TreatmentProtocolsByName(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    treatmentProtocolsByName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        name_scan
        phase
        nextPhaseMinSessions
        nextPhaseMinDays
        numberOfTreatments
        totalDaysLimit
        weeklySessionsLimit
        partialSessionsAllowed
        sessionsCounterType
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const treatmentsByPatient = /* GraphQL */ `
  query TreatmentsByPatient($nafilterme: String) {
    treatmentsByPatient(nafilterme: $nafilterme) {
      items {
        id
        patientId
        sessionId
        timezoneOffset
        phase
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        status
        eventLog {
          timestamp
          status
          emfState
          waveformIndex
          stageId
          categoryId
          exerciseId
          __typename
        }
        notes
        treatmentId
        waveformIndex
        durations
        createdAt
        startedAt
        completedAt
        deletedAt
        zoomUserId
        zoomMeetingId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        categories {
          treatmentStageId
          exerciseCategoryId
          rating
          __typename
        }
        exercises {
          treatmentStageId
          exerciseCategoryId
          exerciseId
          rating
          status
          notes
          startedAt
          elapsed
          __typename
        }
        updateId
        updatedAt
        version
        worksheets {
          timestamp
          role
          userId
          treatmentEMFTime
          deviceEMFTime
          isTreatmentCounted
          operatorPresent
          answers {
            index
            title
            question
            meta
            answer
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const devicesBySerialNumber = /* GraphQL */ `
  query DevicesBySerialNumber(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    devicesBySerialNumber(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        quickConnectNumber
        macAddress
        resistance
        deviceSize
        deletedAt
        updatedAt
        usage {
          timestamp
          tabletUserEmail
          userEmail
          patientIdNumber
          organizationalUnit
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const deviceByQuickConnectNumber = /* GraphQL */ `
  query DeviceByQuickConnectNumber($quickConnectNumber: String!) {
    deviceByQuickConnectNumber(quickConnectNumber: $quickConnectNumber) {
      items {
        id
        serialNumber
        quickConnectNumber
        macAddress
        resistance
        deviceSize
        deletedAt
        updatedAt
        usage {
          timestamp
          tabletUserEmail
          userEmail
          patientIdNumber
          organizationalUnit
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const deviceByCode = /* GraphQL */ `
  query DeviceByCode($code: String!) {
    deviceByCode(code: $code) {
      macAddress
      error
      __typename
    }
  }
`;
export const languagesByName = /* GraphQL */ `
  query LanguagesByName($filter: String!, $limit: Int, $nextToken: String) {
    languagesByName(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        name_scan
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const consentsByPatientId = /* GraphQL */ `
  query ConsentsByPatientId($filter: String!) {
    consentsByPatientId(filter: $filter) {
      items {
        id
        type
        userId
        patientId
        timestamp
        dateTime
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const consentsByUserId = /* GraphQL */ `
  query ConsentsByUserId($filter: String!) {
    consentsByUserId(filter: $filter) {
      items {
        id
        type
        userId
        patientId
        timestamp
        dateTime
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const questionnairesByName = /* GraphQL */ `
  query QuestionnairesByName(
    $filter: String!
    $limit: Int
    $nextToken: String
  ) {
    questionnairesByName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        phase
        name
        name_scan
        description
        isActive
        items {
          index
          required
          type
          title
          question
          questionTranslations {
            code
            text
            __typename
          }
          comment
          commentTranslations {
            code
            text
            __typename
          }
          commentIndex
          options
          optionsTranslations {
            code
            text
            __typename
          }
          enableOther
          autofillField
          readOnlyAutofill
          previousQuestionIndexDependency
          mapOtherTo
          showIfParam
          showIfValue
          __typename
        }
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const textMessagesByName = /* GraphQL */ `
  query TextMessagesByName($filter: String!, $limit: Int, $nextToken: String) {
    textMessagesByName(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        receiver
        message
        status
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const patientAnalyticsByName = /* GraphQL */ `
  query PatientAnalyticsByName(
    $filter: String!
    $sort: BQSort
    $limit: Int
    $nextToken: String
  ) {
    patientAnalyticsByName(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phase
        first_treatment_date
        idNumber
        participationConcluded
        incomplete_treatments_current_week
        last_treatment_date
        multiple_pauses_treatments
        number_fully_completed_treatments
        number_of_initiated_treatments
        number_of_partial_treatments
        organizationalUnit
        patientId
        treatments_current_week
        treatments_previous_week
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getAppData = /* GraphQL */ `
  query GetAppData($langCode: String) {
    getAppData(langCode: $langCode) {
      patients {
        id
        idNumber
        idNumber_scan
        gender
        height
        yearOfBirth
        dateOfBirth
        idOrPassport
        phoneNumber
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        deviceSize
        deviceInfo
        changesInDevice
        caregiverName
        caregiverRelation
        participationConcluded
        assessmentScores {
          timestamp
          shoulderAbduction
          fingerExtension
          WTSeconds
          WTAssistantLevel
          __typename
        }
        numberOfTreatments
        recentTreatmentDate
        controlPatient
        treatmentType
        treatmentProtocolId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        phase
        stats
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      treatmentStages {
        id
        name
        name_scan
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        exerciseCategories
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      exercisesCategories {
        id
        name
        name_scan
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        exercises
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      exercises {
        id
        name
        name_scan
        name_filter
        idInApp
        tip
        description
        nameTranslations {
          code
          text
          __typename
        }
        tipTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        numOfRepetitions
        numOfSets
        secsInsteadOfReps
        illustrationFile
        progressionsList
        equipmentList
        useAssessmentScores
        shoulderAbduction
        fingerExtension
        assessmentScoresMatrix
        walkTest
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      equipments {
        id
        imageUrl
        name
        name_scan
        idInApp
        description
        nameTranslations {
          code
          text
          __typename
        }
        descriptionTranslations {
          code
          text
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      progressions {
        id
        name
        name_scan
        nameTranslations {
          code
          text
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      treatmentProtocols {
        id
        name
        name_scan
        phase
        nextPhaseMinSessions
        nextPhaseMinDays
        numberOfTreatments
        totalDaysLimit
        weeklySessionsLimit
        partialSessionsAllowed
        sessionsCounterType
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      questionnaires {
        id
        role
        phase
        name
        name_scan
        description
        isActive
        items {
          index
          required
          type
          title
          question
          questionTranslations {
            code
            text
            __typename
          }
          comment
          commentTranslations {
            code
            text
            __typename
          }
          commentIndex
          options
          optionsTranslations {
            code
            text
            __typename
          }
          enableOther
          autofillField
          readOnlyAutofill
          previousQuestionIndexDependency
          mapOtherTo
          showIfParam
          showIfValue
          __typename
        }
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      treatments {
        id
        patientId
        sessionId
        timezoneOffset
        phase
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        status
        eventLog {
          timestamp
          status
          emfState
          waveformIndex
          stageId
          categoryId
          exerciseId
          __typename
        }
        notes
        treatmentId
        waveformIndex
        durations
        createdAt
        startedAt
        completedAt
        deletedAt
        zoomUserId
        zoomMeetingId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        categories {
          treatmentStageId
          exerciseCategoryId
          rating
          __typename
        }
        exercises {
          treatmentStageId
          exerciseCategoryId
          exerciseId
          rating
          status
          notes
          startedAt
          elapsed
          __typename
        }
        updateId
        updatedAt
        version
        worksheets {
          timestamp
          role
          userId
          treatmentEMFTime
          deviceEMFTime
          isTreatmentCounted
          operatorPresent
          answers {
            index
            title
            question
            meta
            answer
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
