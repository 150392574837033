/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import {
    TextField,
    Create,
    Edit,
    SelectField,
    DataProviderContext,
    AutocompleteArrayInput,
    LinearProgress,
    useList,
    ListContextProvider,
    Datagrid,
    useRecordContext,
    useNotify
} from 'react-admin';

import { OUSelectInput, setOU } from './organizationUnitSelector.component';
import { getDuplicates, validate, validateDate, validateDuplicates, validateHeight, validateIdNumber, validateNumber, validatePhoneNumber, validateYearOfBirth } from '../utils/validations';
import { TreatmentProtocolComponent, ExerciseCategoryCheckbox, getLists } from './Generic/TreatmentProtocolExerciseData';
import { BQCheckbox, BQLabel, FunctionField, BQSimpleForm, BQSmartIterator, BQAutoCompleteArrayInput } from './Generic/bq-form-components';
import { bqCreateRedirection, bqEditRedirection, charBallot, charCheck, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQProgress, BQSection, BQSideBySide, BQToolbar, FormButton, PhaseSelector } from './Generic/BQUI';
import { bqAuthData } from '../utils/bq-auth';
import { bqBlue } from '../themes';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { RefreshRounded } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { getDateTime, getEMFTimeString, getTranslatedName, getValueByPath } from '../utils/textUtils';
import { Button, CircularProgress } from '@material-ui/core';
import { startVideoMeeting } from '../utils/bq-zoom';
import { BQExporter } from '../utils/bq_export';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { getFromCache } from '../utils/globals';
import { getInitialPhase } from './Generic/UITools';
import DraggableDialog from './Generic/ConfirmationDialog';
import { dateToEDCFormat } from '../utils/bqTools';


let bqExporter = new BQExporter()

const yearOfBirthVerification = 'yearOfBirthVerification'

const confirmationObject = [
    {
        title: `[subjectOrPatient] [idNumber] is set as a [controlPatient] subject`,
        text: `Are you sure you want to set this [subjectOrPatient] as [controlPatient] subject?`,
        condition: (bqForm, record) => bqForm.controlPatient &&
            bqForm.controlPatient != "UNSET" &&
            (!record.idNumber || !record.controlPatient || record.controlPatient === "UNSET")
    },
    {
        title: `Affected side of body for [subjectOrPatient] [idNumber] is set as to [treatmentType]`,
        text: `Are you sure you want to set affected side of the body for this [subjectOrPatient] as [treatmentType]?`,
        condition: (bqForm, record) => bqForm.treatmentType && (!record.idNumber || !record.treatmentType)
    },
    {
        title: `Treatment Protocol Warning`,
        text: `We noticed that you don’t have exercises chosen for stage [value]. Are you sure you want to proceed?`,
        condition: (bqForm) => bqForm.currentTreatmentProtocol?.treatmentStages?.
            filter(stage => !stage.exerciseCategories?.some(cat => cat.selectedExercises.some(exercise => exercise.isEnabled)))
            ?.map(stage => stage.name).reduce((acc, stage) => `${acc}, ${stage}`, '')
    },
    {
        title: `[subjectOrPatient] has completed treatment / discontinued`,
        text: `Are you sure that you want to mark [subjectOrPatient] [idNumber] as complete / discontinue? This [subjectOrPatient] will no longer appear in the BQ app`,
        condition: (bqForm, record) => bqForm.participationConcluded && !record.participationConcluded
    }
]

const PatientList = (props) => {
    const { appSettings } = props
    const { Phases, gender, relation, yesMapping } = appSettings || {}
    const initialPhase = getInitialPhase(Phases)

    const { isMarketTargetAudience, isSuperAdmin, isAdmin, isOperator, isViewer, subjectOrPatient } = bqAuthData
    const roleOtherThanRandomizer = bqAuthData.role.some(role => role.indexOf('Admin') !== -1)

    const exporterRef = bqExporter.createRefForExport(`${subjectOrPatient}s`)

    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => setTimeout(() => setState({
            isLoadingDictionaries: false,
            cache: data
        }), 500))
    }, [dataProvider]);

    return (
        <BQModelList
            dataGridRef={exporterRef}
            disableDelete={true}
            nameField="idNumber"
            showDates={true}
            expand={(isAdmin || isOperator || isViewer) && <TreatmentsData yesMapping={yesMapping} /> || null}
            expandSingle={false}
            exporter={() => bqExporter.exportData(`Patients`, true)}
        >
            <TextField label={`${isMarketTargetAudience ? 'Patient name' : 'Subject ID'}`} source="idNumber" />
            {roleOtherThanRandomizer && <SelectField label="Sex" source="gender" choices={gender || []} />}
            <TextField source="organizationalUnit" label="Clinic" />
            {roleOtherThanRandomizer && (
                state?.cache && <FunctionField
                    label="Treatment protocol"
                    source="treatmentProtocolId"
                    value={(val) => {
                        return state.cache.treatmentProtocolsCache.find(item => item.id == val)?.name || 'N/A'
                    }} /> || <LinearProgress />)
            }
            {Phases && <FunctionField source="phase" label="Clinical study stage" value={v => (Phases?.find(item => item.id === v) || initialPhase)?.name} />}
            {roleOtherThanRandomizer && <SelectField source="caregiverRelation" label="Nature of caregiver relationship" choices={relation || []} />}
            {!isMarketTargetAudience && <FunctionField label="Experimental group selected" source="controlPatient" value={(v) => v?.toLowerCase() !== 'unset' ? charCheck : ''} />}
            <FunctionField label="Completed / Discontinued" source="participationConcluded" value={(v) => !!v ? charCheck : ''} />
        </BQModelList>
    )
}

const TreatmentsData = (props) => {
    const { record, yesMapping } = props
    const patientId = record?.id
    const dataProvider = React.useContext(DataProviderContext);
    const [treatmentsData, setTreatmentsData] = React.useState({
        isLoading: true
    })

    useEffect(() => {
        dataProvider.getList('questionnairesAnswersForPatient',
            {
                pagination: { perPage: 10000 },
                filter: patientId
            })
            .then(response => {
                setTreatmentsData(response?.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [])

    return treatmentsData?.isLoading ? <BQProgress /> : <QuestionnairesList
        data={treatmentsData}
        patientId={patientId}
        idNumber={record.idNumber}
        yesMapping={yesMapping?.map(item => item.toLowerCase())}
    />
}

const QuestionnairesList = (props) => {
    const { data, patientId, idNumber, yesMapping } = props
    const { subjectOrPatient } = bqAuthData
    bqExporter.setFileName(`${idNumber} Worksheets`)
    if (!data || !data.length) {
        return <div style={{ textAlign: 'center', padding: '32px' }}>No worksheets data for this patient</div>
    }

    const caregiverWorksheets = data.map((item, index) => {
        const caregiverWorksheet = item.worksheets.find(worksheet => worksheet.role === 'Caregiver')
        const { treatmentDate } = item
        const {
            userId,
            role,
            operatorPresent,
            answers,
            deviceEMFTime,
            treatmentEMFTime,
            isTreatmentCounted,
        } = caregiverWorksheet || {}

        return {
            id: `(${patientId})_${index + 1}`,
            treatmentDate,
            treatmentDateTimestamp: new Date(treatmentDate).getTime(),
            userId,
            isTreatmentCounted,
            deviceEMFTime,
            treatmentEMFTime,
            isTreatmentCounted,
            role,
            operatorPresent,
            answers
        }
    })

    const headers = [...caregiverWorksheets].sort((a, b) =>
        (b?.answers?.length || 0) - (a?.answers?.length || 0))
        ?.[0]?.answers?.map(answer => answer.title)
        ?.sort((a, b) => {
            const aParts = a.split('.')
            const bParts = b.split('.')
            const aNumber = parseInt(aParts[0].split('Q')[1])
            const bNumber = parseInt(bParts[0].split('Q')[1])
            if (aNumber === bNumber) {
                if (aParts.length === 1) {
                    return -1
                }
                if (bParts.length === 1) {
                    return 1
                }
                return aParts[1].localeCompare(bParts[1])
            }
            return aNumber - bNumber
        })

    const exporterRef = bqExporter.createRefForExport(`${idNumber} - Worksheets`)

    const listContext = useList({
        data: caregiverWorksheets,
        ids: caregiverWorksheets?.map(item => item.id)
    })

    return caregiverWorksheets?.length ? (
        <BQSection title={`WorkSheets`} style={{ maxWidth: '100%', padding: '24px' }} headerPadding={0}>
            <Button variant="outlined" style={{ padding: 8, margin: '8px 0px' }} onClick={() => bqExporter.exportData(`${subjectOrPatient}s`)}>Export Worksheet</Button>
            <ListContextProvider value={listContext}>
                <Datagrid
                    style={{ width: 4000 }}
                    ref={exporterRef}
                    rowStyle={questionnaireRowStyle}
                    bulkActionButtons={false}>
                    <FunctionField label={`${subjectOrPatient} ID`} value={(v) => idNumber} />
                    <FunctionField label="Treatment Date (Subject tz)" value={(v, record) => dateToEDCFormat(record.treatmentDate, record.timezoneOffset)} />
                    <FunctionField label="Treatment Date (local tz)" source="treatmentDate" value={v => getDateTime(v)} />
                    <FunctionField label="Conducted by" source="userId" defaultValue="N/A" />
                    {headers?.map((header, index) => {
                        return <FunctionField
                            label={header || 'null'}
                            value={val => {
                                let fieldValue = val.answers?.find(item => item.title == header)?.answer
                                if (header.toLowerCase().indexOf('q5.') !== -1) {
                                    const q5Answer = val.answers?.find(item => item.title.toLowerCase().startsWith('q5 ')).answer
                                    if (yesMapping?.includes(q5Answer?.toLowerCase())) {
                                        fieldValue = null
                                    }
                                }
                                const displayValue = fieldValue || 'N/A'
                                const lineStyle = {
                                    textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '256px'
                                }
                                if (val.answers?.length && !fieldValue) {
                                    lineStyle.color = 'red'
                                    lineStyle.fontWeight = 'bold'
                                }
                                return <div style={lineStyle}>{displayValue}</div>
                            }}
                            title={val => {
                                const answerItem = val.answers?.[index]
                                return `${answerItem?.question || ''}\n\n${answerItem?.answer || ''}`.trim()
                            }} />
                    })}
                    <FunctionField label="EMF Duration" source="treatmentEMFTime" value={(treatmentEMFTime, record) => getEMFTimeString(treatmentEMFTime, record.deviceEMFTime)} />
                    <FunctionField label="Counted" source="isTreatmentCounted" value={(v) => v ? charCheck : charBallot} />
                    <FunctionField label="Missing" value={val => {
                        const emptyWorksheet = !val.answers?.length ?
                            (val.operatorPresent ? 'Operator Present' : 'Skipped')
                            : null

                        const answerRequired = val.answers?.some(item => JSON.parse(item.meta)?.answerRequired && !item.answer.length)

                        return (answerRequired && 'Missing') || emptyWorksheet || 'N/A'
                    }
                    } />
                </Datagrid >
            </ListContextProvider >
        </BQSection>
    ) : <></>
}

const questionnaireRowStyle = (row) => !row.operatorPresent && !row.answers?.length
    ? { backgroundColor: 'rgba(128, 0, 0, 0.2)' }
    : {};

const handlePhaseChange = ({ formContext, selection, currentPhase, setCurrentPhase }) => {
    if (currentPhase.id !== selection.id) {
        formContext.setValue('treatmentProtocolId', null, { shouldDirty: true, shouldTouch: true })
        formContext.setValue('treatmentStages', [], { shouldDirty: true, shouldTouch: true })
        setCurrentPhase(selection)
    }
}

const PhaseChangeConfirmationDialog = ({ nextPhaseMinDays, onConfirm, handleClose, opened }) => {
    const { subjectOrPatient } = bqAuthData
    const formContext = useFormContext()
    return <DraggableDialog
        confirmText='Confirm'
        open={opened}
        title={`Confirm stage completion`}
        onConfirm={
            (e) => formContext.trigger(yearOfBirthVerification)
                .then((isValid) => isValid && onConfirm({ e, formContext }))
        }
        handleClose={handleClose}
        content={
            <div>
                Please make sure that the {subjectOrPatient.toLowerCase()} has completed day {nextPhaseMinDays} assessment
                <br />
                <br />
                To continue please type the {subjectOrPatient.toLowerCase()}'s year of birth:
                <table style={{ width: '100%' }}>
                    <BQInput source={yearOfBirthVerification} noLabel maxLength={4}
                        validate={() => {
                            const yearOfBirth = formContext.getValues().yearOfBirthVerification
                            return parseInt(yearOfBirth) === parseInt(formContext.getValues().yearOfBirth)
                                ? undefined
                                : `${subjectOrPatient}'s year of birth does not match`
                        }}
                    />
                </table>
            </div>
        } />
}

const TreatmentProtocolSelector = ({ Phases, cache, currentPhase, readOnly }) => {
    const formContext = useFormContext()

    const [treatmentProtocolChoices, setTreatmentProtocolChoices] = useState([])

    useEffect(() => {
        setTreatmentProtocolChoices(Phases
            ? (cache?.treatmentProtocolsCache
                ?.filter(tp => !currentPhase || tp.phase === currentPhase.id))
                ?.map(tp => ({ id: tp.id, name: tp.name }))
            : cache?.treatmentProtocolsCache)
    }, [Phases, currentPhase, cache])

    useEffect(() => {
        if (treatmentProtocolChoices?.length === 1) {
            formContext.setValue('treatmentProtocolId', treatmentProtocolChoices[0].id, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
        }
    }, [treatmentProtocolChoices])

    return <BQDropDown
        label="Treatment protocol"
        source="treatmentProtocolId"
        choices={treatmentProtocolChoices}
        validate={validate}
        allowDuplicates={true}
        readOnly={readOnly} />
}

const PatientEditor = (props) => {
    const { isSuperAdmin, isAdmin, isRandomizer, isOperator, isViewer, role, isMarketTargetAudience, subjectOrPatient, isAlzheimers } = bqAuthData
    const canEditTreatment = isAdmin || isOperator
    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    const notify = useNotify()
    const record = useRecordContext()
    const displayVideoButton = record.treatmentProtocolId && record.controlPatient === 'SET' && record.treatmentType && isOperator

    const roleOtherThanRandomizer = role.some(role => role.toLowerCase().indexOf('random') === -1)
    const displayTreatmentShamComponents = ((!record.idNumber || !record.controlPatient || record.controlPatient === 'UNSET') && isRandomizer)

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: `A ${subjectOrPatient?.toLowerCase()}`,
        duplicates: props.duplicates
    }

    const { appSettings, cache } = props
    const { Phases,
        gender,
        treatmentTypes,
        deviceType,
        relation,
        changesInDevice: changesInDeviceData,
        controlPatientOptions,
        WTAssistantLevels,
        choicesTreatmentStageType
    } = appSettings || {}
    const initialPhase = getInitialPhase(Phases)
    const isAssessmentScoresReadOnly = !isAdmin && !isOperator

    const [changesInDeviceOptions, setChangesInDeviceOptions] = useState([])
    const [currentPhase, setCurrentPhase] = useState()
    const [phaseChangeDialogOpened, setPhaseChangeDialogOpened] = useState(false)
    const [phasesChangeButtonEnabled, setPhasesChangeButtonEnabled] = useState(false)
    const [phasesEnabled, setPhasesEnabled] = useState(false)
    const [numberOfPhaseTreatments, setNumberOfPhaseTreatments] = useState(record?.numberOfTreatments)

    const currentTreatmentProtocol = cache?.treatmentProtocolsCache?.find(tp => tp.id === record?.treatmentProtocolId)

    useEffect(() => {
        const changesInDeviceId = deviceType?.find(item => item.id === record?.deviceSize)?.changesInDeviceId
        setChangesInDeviceOptions(changesInDeviceData?.[changesInDeviceId] || [])
    }, [changesInDeviceData])

    useEffect(() => {
        if (Phases) {
            if (record?.phase) {
                setCurrentPhase(Phases.find(item => item.id === record.phase))
            } else {
                setCurrentPhase(initialPhase)
            }
        }
        if (!numberOfPhaseTreatments) {
            if (record?.stats) {
                const stats = JSON.parse(record.stats)
                setNumberOfPhaseTreatments(stats?.[record.phase]?.numberOfTreatments)
            } else {
                setNumberOfPhaseTreatments(record?.numberOfTreatments)
            }
        }
    }, [Phases, record])

    return (
        <BQSimpleForm
            prepareForm={({ formData, setValue }) => {
                if (!formData.hasOwnProperty('recentAssessmentScores')) {
                    const assessmentScoresSorted = formData.assessmentScores
                    assessmentScoresSorted?.sort((a, b) => b.timestamp - a.timestamp)
                    const recentAssessmentScores = assessmentScoresSorted?.[0]
                    const previousAssessmentScores = assessmentScoresSorted?.[1]

                    setValue('recentAssessmentScores', recentAssessmentScores && { ...recentAssessmentScores } || {})
                    setValue('previousAssessmentScores', previousAssessmentScores)
                    if (formData.changesInDevice) {
                        const changesInDevice = []
                        let changesInDeviceOther = undefined
                        try {
                            JSON.parse(formData.changesInDevice)?.forEach(item => {
                                if (item.id === -1) {
                                    changesInDeviceOther = item.name
                                } else {
                                    changesInDevice.push(item.name)
                                }
                            })
                        } catch { }
                        setValue('changesInDevice', changesInDevice)
                        setValue('changesInDeviceOther', changesInDeviceOther)
                    }
                }
            }}
            toolbar={<BQToolbar type={subjectOrPatient} nameField="idNumber" confirmation={confirmationObject} />}>
            {displayVideoButton && <VideoMeetingButton />}
            <BQSideBySide>
                <BQSection title={`${isMarketTargetAudience ? 'Patient' : 'Subject'} Info`} readOnly={!isAdmin}>
                    <BQInput
                        label={`${isMarketTargetAudience ? 'Patient name' : 'Subject ID'}`}
                        placeholder={`${isMarketTargetAudience ? '' : 'Site ID-Subject ID (XXX-YY)'}`}
                        source="idNumber"
                        validate={isMarketTargetAudience ? validate : validateIdNumber}
                        duplicates={duplicatesCheckData}
                        readOnly={!!record.idNumber && !(isSuperAdmin || isAdmin)} />
                    {isMarketTargetAudience &&
                        <>
                            <BQInput label="Date of birth" source="dateOfBirth" type="date" validate={validateDate} />
                            <BQInput label="ID number" source="idOrPassport" placeholder="ID number/Passport number" validate={validate} />
                            <BQInput type="phoneNumber" label="Phone number" source="phoneNumber" validate={validatePhoneNumber} placeholder="Phone number with country code" />
                        </>
                    }
                    {!isMarketTargetAudience && roleOtherThanRandomizer && <BQInput label="Year of birth" source="yearOfBirth" validate={validateYearOfBirth} minLength={4} maxLength={4} />}
                    {roleOtherThanRandomizer &&
                        <>
                            <BQDropDown
                                label="Sex"
                                source="gender"
                                choices={gender}
                                validate={validate}
                                unsorted
                            />
                            <BQInput source="height" placeholder={isMarketTargetAudience ? 'In cm (for example: 180)' : 'Feet’Inches (for example: 5’2)'} validate={validateHeight} {...(isMarketTargetAudience ? { maxLength: 3 } : {})} />
                            <BQDropDown source="caregiverRelation" label="Nature of caregiver relationship" choices={relation} unsorted />
                            {isSuperAdmin && <OUSelectInput label="Clinic" />}
                        </>
                    }
                </BQSection>


                {isAdmin &&

                    <BQSection title={Phases ? `Long term active stimulation group` : 'Treatment completion'}>
                        {record?.id && Phases && <>
                            {currentPhase?.nextPhase && currentTreatmentProtocol?.nextPhaseMinSessions && (!record.phase || record.phase === initialPhase?.id) && !phasesEnabled &&
                                <>
                                    <tr><td style={{ width: '100%', paddingTop: '24px' }}>
                                        <BQCheckbox
                                            disabled={true}
                                            id='stageMinDaysCompleted'
                                            label={`${subjectOrPatient} has completed day ${currentTreatmentProtocol?.nextPhaseMinDays} assessment`}
                                            onChange={({ newValue }) => {
                                                setPhasesChangeButtonEnabled(newValue && numberOfPhaseTreatments >= currentTreatmentProtocol?.nextPhaseMinSessions)
                                            }}
                                        /></td></tr>
                                    <tr>
                                        <td style={{ padding: '16px 0' }}>
                                            <FormButton
                                                id='bt_proceedToNextStage'
                                                variant={phasesChangeButtonEnabled ? 'containedPrimary' : 'outlined'}
                                                disabled={!phasesChangeButtonEnabled || numberOfPhaseTreatments < currentTreatmentProtocol?.nextPhaseMinSessions}
                                                onClick={({ formContext }) => {
                                                    setPhaseChangeDialogOpened(true)
                                                }}>
                                                {subjectOrPatient} PROCEEDS TO NEXT STAGE
                                            </FormButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <span id="lbl_phaseSessionsOutOfRequired">{`${numberOfPhaseTreatments || 0} sessions completed out of the ${currentTreatmentProtocol?.nextPhaseMinSessions} required for the next stage`}</span>
                                    </tr>
                                    <PhaseChangeConfirmationDialog
                                        nextPhaseMinDays={currentTreatmentProtocol?.nextPhaseMinDays}
                                        opened={phaseChangeDialogOpened}
                                        onConfirm={({ formContext }) => {
                                            setPhaseChangeDialogOpened(false)
                                            const nextPhase = Phases.find(item => item.id === currentPhase?.nextPhase)
                                            formContext.setValue('phase', nextPhase?.id, { shouldDirty: true, shouldTouch: true })
                                            formContext.setValue('treatmentProtocolId', null, { shouldDirty: true, shouldTouch: true })
                                            formContext.setValue(yearOfBirthVerification, undefined, { shouldDirty: true, shouldTouch: true })
                                            handlePhaseChange({ formContext, selection: nextPhase, currentPhase, setCurrentPhase })
                                            setPhasesEnabled(true)
                                            notify(`Clinical study stage was updated.`, { type: 'info' })
                                        }}
                                        handleClose={() => setPhaseChangeDialogOpened(false)}
                                    />
                                </>
                                ||
                                <><br /><span id="lbl_lastClinicalStage">{subjectOrPatient} is allocated to the last clinical study stage</span><br /></>
                            }
                            <br />
                            <hr />

                        </>}
                        <br />
                        <BQCheckbox label={`${subjectOrPatient} has completed treatment / discontinued`} source="participationConcluded" />
                    </BQSection>
                }
            </BQSideBySide>
            <BQSideBySide>
                {(roleOtherThanRandomizer || displayTreatmentShamComponents) && <BQSection title={`Treatment Info ${isEnglish ? '' : ' - ' + langName}`} readOnly={!isAdmin}>
                    {roleOtherThanRandomizer &&
                        <>
                            <BQDropDown
                                source="deviceSize"
                                label="Device type"
                                choices={deviceType}
                                unsorted
                                onChange={(value) => {
                                    const changesInDeviceId = value?.selection?.changesInDeviceId
                                    setChangesInDeviceOptions(changesInDeviceData?.[changesInDeviceId] || [])
                                }}
                            />
                            <ChangesInDevice {...{ choices: changesInDeviceOptions }} />
                        </>
                    }


                    {!isMarketTargetAudience && !isAlzheimers && displayTreatmentShamComponents && <BQDropDown source="controlPatient" label="Treatment/Sham group" choices={controlPatientOptions} unsorted readOnly={false} validate={validate} />}

                    {roleOtherThanRandomizer &&
                        <>
                            {!isAlzheimers && <>
                                <BQDropDown source="treatmentType" label={treatmentTypes?.label} choices={treatmentTypes?.choices} unsorted validate={validate} readOnly={(record.idNumber && record.treatmentType)} />
                            </>}
                            {currentPhase && <PhaseSelector
                                required
                                readOnly={!phasesEnabled}
                                defaultValue={currentPhase?.id}
                                appSettings={appSettings}
                                onChange={({ formContext, selection }) => handlePhaseChange({ formContext, selection, currentPhase, setCurrentPhase })}
                            />}
                            <TreatmentProtocolSelector {...{
                                Phases,
                                cache,
                                currentPhase,
                                readOnly: !isAdmin
                            }} />
                        </>
                    }
                </BQSection>
                }
                <BQSection title="Assessment scores" visible={isAdmin || isOperator || isViewer}>
                    <BQInput
                        type="number"
                        min={0}
                        max={5}
                        validate={validateNumber}
                        nullable
                        source="recentAssessmentScores.shoulderAbduction"
                        label="Shoulder abduction"
                        onChange={handleAssessmentScoresChange}
                        readOnly={isAssessmentScoresReadOnly}
                    />
                    <BQInput
                        type="number"
                        min={0}
                        max={5}
                        validate={validateNumber}
                        nullable
                        source="recentAssessmentScores.fingerExtension"
                        label="Finger extension"
                        onChange={handleAssessmentScoresChange}
                        readOnly={isAssessmentScoresReadOnly}
                    />
                    <BQInput
                        type="number"
                        min={0}
                        validate={validateNumber}
                        nullable
                        source="recentAssessmentScores.WTSeconds"
                        label="10mWT - Seconds"
                        onChange={handleAssessmentScoresChange}
                        readOnly={isAssessmentScoresReadOnly}
                    />
                    <BQDropDown
                        label="10mWT - Assistant level"
                        source="recentAssessmentScores.WTAssistantLevel"
                        choices={WTAssistantLevels}
                        onChange={handleAssessmentScoresChange}
                        readOnly={isAssessmentScoresReadOnly}
                    />
                    {!isAssessmentScoresReadOnly && <tr>
                        <td colspan="2">
                            <ApplyToProtocolButton {...props} />
                        </td>
                    </tr>}
                    <PreviousAssessmentScores />
                </BQSection>
            </BQSideBySide>
            {
                roleOtherThanRandomizer && <>
                    <TreatmentProtocolComponent {...props} treatmentStagesContainer="currentTreatmentProtocol" >
                        <BQSmartIterator source="currentTreatmentProtocol.treatmentStages">
                            <BQLabel label={({ value }) => getTranslatedName(value, langCode)} />
                            <BQLabel label="Stage Duration: [source] minutes" source="treatmentStageDuration" variant="body2" />
                            <BQLabel label={({ value }) => `Stage Type: ${choicesTreatmentStageType?.find(item => item.id === value)?.name}`} source="type" variant="body2" />
                            <BQSmartIterator source="exerciseCategories">
                                <div style={{ padding: '24px' }}>
                                    <ExerciseCategoryCheckbox {...props} langCode={langCode} readOnly={!canEditTreatment} />
                                </div>

                                <table style={{ width: '1200px', marginLeft: '48px', borderCollapse: 'collapse' }}>
                                    <tr style={headerStyle}>
                                        <td style={tableCellWidth(300)}><BQLabel style={titleStyle} label="Name" variant="body2" /></td>
                                        <td style={numberCell}><BQLabel style={titleStyle} label="Sets" variant="body2" /></td>
                                        <td style={numberCell}><BQLabel style={titleStyle} label="Repetitions" variant="body2" /></td>
                                        <td style={tableCellWidth(16)}></td>
                                        <td><BQLabel style={titleStyle} label="Progressions" variant="body2" /></td>
                                        <td style={tableCellWidth(160)}></td>
                                    </tr>

                                    <BQSmartIterator source="selectedExercises" >
                                        <ExerciseComponent canEditTreatment={canEditTreatment} />
                                    </BQSmartIterator>
                                </table>
                            </BQSmartIterator>
                        </BQSmartIterator>
                    </TreatmentProtocolComponent>
                </>
            }
        </BQSimpleForm >
    )
}

const ChangesInDevice = ({ choices }) => {
    const { isAdmin } = bqAuthData
    const bqClasses = getFromCache('bqClasses')

    return <tr>
        <td className={bqClasses.inputLabel} style={{ verticalAlign: 'top' }}>
            Changes in device
        </td>
        <td>
            <BQAutoCompleteArrayInput source="changesInDevice" choices={choices} readOnly={!isAdmin} />
            <span className={bqClasses.inputLabel}>Other - Specify below:</span>
            <table style={{ width: '100%' }}>
                <BQInput noLabel source="changesInDeviceOther" multiline readOnly={!isAdmin} />
            </table>
        </td>
    </tr>
}

const PreviousAssessmentScores = (props) => {
    const formValues = useFormContext().getValues()
    const { previousAssessmentScores } = formValues
    if (previousAssessmentScores) {
        return <tr>
            <td colspan="2">
                <BQSection title={`Previous values: (${getDateTime(previousAssessmentScores.timestamp)})`} headerPadding={16}>
                    <p>
                        <b id="previous_values_shoulder_abduction_label">Shoulder abduction:</b> <span id="previous_values_shoulder_abduction_value">{previousAssessmentScores.shoulderAbduction}</span><br />
                        <b id="previous_values_finger_extension_label">Finger extension:</b> <span id="previous_values_finger_extension_value">{previousAssessmentScores.fingerExtension}</span><br />
                        <b id="previous_values_10mWT_label">10mWT:</b> <span id="previous_values_10mWT_seconds_value">{previousAssessmentScores.WTSeconds}</span> <span id="previous_values_10mWT_other_label">seconds, Assistance level:</span> <span id="previous_values_10mWT_assistant_level_value">{previousAssessmentScores.WTAssistantLevel}</span>
                    </p>
                </BQSection>
            </td>
        </tr>
    }
    return null
}

const ApplyToProtocolButton = (props) => {
    const formContext = useFormContext()
    const formValues = formContext.getValues()

    return <Button id="apply_to_protocol_button" variant="outlined" style={{ padding: 8, margin: '8px 0px' }} onClick={() =>
        formValues.currentTreatmentProtocol?.treatmentStages?.forEach((stage, stageIndex) =>
            stage.exerciseCategories?.forEach((category, cagetoryIndex) =>
                category.selectedExercises?.forEach((exercise, exerciseIndex) => {
                    const cachedExercise = props.cache?.exercisesCache?.find(item => item.id === exercise.exerciseId)
                    if (cachedExercise?.useAssessmentScores) {
                        const source = `currentTreatmentProtocol.treatmentStages[${stageIndex}].exerciseCategories[${cagetoryIndex}].selectedExercises[${exerciseIndex}].isEnabled`

                        const patientShoulderAbduction = parseInt(formValues.recentAssessmentScores?.shoulderAbduction) - 1
                        const patientFingerExtension = parseInt(formValues.recentAssessmentScores?.fingerExtension) - 1

                        const isEnabled = cachedExercise.assessmentScoresMatrix?.[patientFingerExtension]?.[patientShoulderAbduction] === 1
                        formContext.setValue(source, isEnabled)
                    }
                })))
    }>Apply to protocol</Button>
}

const handleAssessmentScoresChange = ({ formContext }) => formContext?.setValue('recentAssessmentScores.timestamp', null)

const titleStyle = {
    padding: '16px 16px 16px 8px',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.8)'
}

const headerStyle = {
    fontSize: '16px',
    borderRight: '0px',
    borderLeft: '0px',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.1)',
    borderSpacing: 0,
    verticalAlign: 'top'
}


const tableCellWidth = (width) => {
    return {
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        width: `${width}px`
    }
}
const numberCell = tableCellWidth(96)


const ExerciseComponent = (props) => {
    const bqClasses = getFromCache('bqClasses')
    const formContext = useFormContext()
    const inputValue = getValueByPath(formContext.getValues(), props.source)


    const { canEditTreatment, language } = props
    const { code: langCode } = language || {}

    const parseSource = (sourceName) => `${props.source}.${sourceName}`

    const undefinedOrNull = (val) => {
        return (Array.isArray(val) && val.length == 0) || val === undefined || val === null
    }
    const createChoices = (numbers) => numbers.map(num => {
        return { name: `${num}`, id: `${num}` }
    })

    const { numOfSets, numOfRepetitions, progressionsList, secsInsteadOfReps, availableProgressions } = inputValue || {}
    const secsRepetition = secsInsteadOfReps ? '(Seconds)' : ''
    const repsChoices = createChoices(secsInsteadOfReps ? [10, 20, 30, 40, 50, 60] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
    const isDefault = undefinedOrNull(numOfSets) && undefinedOrNull(numOfRepetitions) && undefinedOrNull(progressionsList)

    return (
        <tr className={bqClasses.bqTableRow}>
            <td><div className={bqClasses.exerciseForPatientContent}><BQCheckbox variant={'caption'} source={parseSource('isEnabled')} label={({ record }) => getTranslatedName(record, langCode)} readOnly={!canEditTreatment} /></div></td>
            <td><div className={bqClasses.exerciseForPatientContent}><BQDropDown label="" source={parseSource('numOfSets')} borderless defaultValueSource={parseSource('orgNumOfSets')} choices={createChoices([1, 2, 3, 4, 5])} readOnly={!canEditTreatment} /></div></td>
            <td><div className={bqClasses.exerciseForPatientContent}><BQDropDown label="" source={parseSource('numOfRepetitions')} borderless defaultValueSource={parseSource('orgNumOfRepetitions')} choices={repsChoices} readOnly={!canEditTreatment} /></div></td>
            <td><div style={{ fontSize: '11px' }}>{secsRepetition}</div></td>
            <td>
                <div className={bqClasses.exerciseForPatientContent} style={canEditTreatment ? { paddingTop: '24px' } : {}} >
                    {canEditTreatment ?
                        <AutocompleteArrayInput
                            id={`${parseSource('progressionsList')}_input`}
                            choices={availableProgressions}
                            source={parseSource('progressionsList')}
                            label=""
                            optionValue="id"
                            variant="outlined"
                            optionText={(item) => getTranslatedName(item, langCode)}
                        />
                        :
                        <FunctionField label="" value={item => {
                            const data = getValueByPath(item, parseSource('progressionsList').replace('currentTreatmentProtocol.', ''))
                            return data?.map(element => availableProgressions.find(av => av.id === element)?.name)?.join(', ')
                        }} />
                    }
                </div>
            </td>
            <td onClick={() => {
                formContext.setValue(parseSource('progressionsList', undefined, { shouldDirty: true }))
                formContext.setValue(parseSource('numOfSets', undefined, { shouldDirty: true }))
                formContext.setValue(parseSource('numOfRepetitions', undefined, { shouldDirty: true }))
                formContext.resetField(parseSource('progressionsList'), { shouldDirty: true })
                formContext.resetField(parseSource('numOfSets'), { shouldDirty: true })
                formContext.resetField(parseSource('numOfRepetitions'), { shouldDirty: true })
            }}>
                <div className={bqClasses.exerciseForPatientContent} style={{ color: bqBlue, marginTop: '-8px' }}>
                    {!isDefault && canEditTreatment && <table style={{ cursor: 'pointer' }}><tr><td><RefreshRounded style={{ display: 'inline-block', transform: 'scaleX(-1)', fontSize: '24px' }} /></td><td><BQLabel variant="body2" label={'Switch to Default'} style={{ color: bqBlue }} /></td></tr></table>}
                </div>
            </td>
        </tr>
    )
}

const VideoMeetingButton = (props) => {
    const dataProvider = useContext(DataProviderContext);
    const record = useRecordContext()
    const [loadingMeetingData, setLoadingMeetingData] = useState(false)
    return <div style={{ position: 'fixed', left: '50%', top: '48px', zIndex: 99999 }}>
        <Button
            id="btStartMeeting"
            className="MuiButton-contained"
            style={{
                boxShadow: 'none'
            }}
            onClick={(e) => {
                if (loadingMeetingData) {
                    return
                }
                setLoadingMeetingData(true)
                dataProvider.getOne('zoomMeetingDataa', {
                    id: {
                        patientId: record?.id,
                        timezoneOffset: new Date().getTimezoneOffset() / -60.0
                    }
                })
                    .then(response => {
                        const meetingData = response?.data;
                        if (meetingData?.meetingNumber) {
                            startVideoMeeting({
                                sdkKey: meetingData.zoomSDKKey,
                                signature: meetingData.zoomSDKToken,
                                meetingNumber: meetingData.meetingNumber,
                                password: meetingData.meetingPassword,
                                userName: `${bqAuthData.name} (Operator)`,
                                zak: meetingData.zoomToken
                            }).finally(() => {
                                setLoadingMeetingData(false)
                            })
                        } else {
                            setLoadingMeetingData(false)
                            console.error(response)
                            alert('Could not initiate a video meeting. If this problem persists please contact BRAIN.Q support')
                        }
                    })
                    .catch(error => {
                        setLoadingMeetingData(false)
                        console.error(error)
                        alert('Could not initiate a video meeting. If this problem persists please contact BRAIN.Q support')
                    })
            }}>
            <div style={{ background: loadingMeetingData ? 'none' : 'url(\'./icons/video_meeting.svg\')', width: 24, height: 24 }}>
                {loadingMeetingData && <CircularProgress style={{ width: '16px', height: '16px', marginTop: '2px' }} />}
            </div>&nbsp;START A MEETING
        </Button>
    </div >
}

const patientDataTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Patient', data, ['idNumber']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    const treatmentStages = data.currentTreatmentProtocol?.treatmentStages.map((stage, stageIndex) => ({
        index: stageIndex,
        treatmentStageId: stage.id,
        treatmentStageDuration: stage.treatmentStageDuration || 0,
        type: stage.type,
        deviceEnabled: null,
        exercisesCategories: stage.exerciseCategories.map((category, categoryIndex) => ({
            index: categoryIndex,
            exerciseCategoryId: category.exerciseCategoryId,
            selectedExercisesIndexed: category.selectedExercises.map((exercise, exerciseIndex) => ({
                index: exerciseIndex,
                isEnabled: !!exercise.isEnabled,
                exerciseId: exercise.exerciseId,
                numOfRepetitions: parseInt(exercise.numOfRepetitions) || null,
                numOfSets: parseInt(exercise.numOfSets) || null,
                secsInsteadOfReps: !!exercise.secsInsteadOfReps,
                progressionsList: exercise.progressionsList?.length && exercise.progressionsList || null
            }))
        }))
    }))
    setOU(data)

    if (data.changesInDevice || data.changesInDeviceOther) {
        if (typeof data.changesInDevice === 'string') {
            try {
                data.changesInDevice = JSON.parse(data.changesInDevice)
            }
            catch {
                data.changesInDevice = []
            }
        }
        data.changesInDevice = JSON.stringify(
            data.changesInDevice
                .map((item, index) => ({ id: index, name: item }))
                .concat(data.changesInDeviceOther ? [{ id: -1, name: data.changesInDeviceOther }] : [])
                .sort((a, b) => a.id - b.id)
        )
        delete data.changesInDeviceOther
    }

    delete data.treatmentProtocol;
    delete data.currentTreatmentProtocol;
    delete data.organizationalUnit_scan;
    delete data.assessmentScores
    delete data.previousAssessmentScores
    delete data.numberOfTreatments
    delete data.recentTreatmentDate
    delete data.stats
    delete data.deviceInfo
    delete data.undefined

    Object.keys(data).filter(key => key.match(/PerPhase/)).forEach(key => delete data[key])

    if (data?.controlPatient?.toLowerCase() === 'set') {
        delete data.controlPatient
    }
    if (treatmentStages) {
        data.treatmentStages = treatmentStages;
    }
    return prepareFormData(data)
}

const PatientMutate = (isCreate, props) => {
    const { language } = props
    const [duplicates, setDuplicates] = useState();
    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => setTimeout(() => setState({
            isLoadingDictionaries: false,
            cache: data
        }), 500))
    }, []);


    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => patientDataTransform(data, setDuplicates) }
    if (isCreate) {
        editorProps.record = { controlPatient: 'UNSET' }
    }
    return (
        <div>
            {isCreate ?
                (<Create {...editorProps}>
                    <PatientEditor {...props} language={language} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...editorProps} >
                    <PatientEditor {...props} language={language} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const PatientCreate = (props) => PatientMutate(true, props)

const PatientEdit = (props) => PatientMutate(false, props)

export default BQCRUDBuilder({
    Create: PatientCreate,
    Edit: PatientEdit,
    List: PatientList,
})